import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
// CUSTOM COMPONENT
import { formazione } from "../data/cvCinofilo";
import InfoCard from "../components/InfoCard";
import ImageCard from "../components/ImageCard";
// IMAGES
import ImgIoTaiSelfie from "../static/images/img-io-tai-selfie.jpg";
import LogoZampa from "../static/images/logo-zampa.png";

const getItemKey = (item) => item.anno + item.mese + item.titolo;

const CustomListItem = styled(ListItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.grey.lighter,
  },
}));

const AnnoListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
}));

const RowItem = ({ item, onPreviewClick, isLast }) => (
  <>
    <CustomListItem>
      <Grid container spacing={{ xs: 2, sm: 4 }} alignItems="center">
        <Grid item xs={3} sm={2}>
          <Typography color="secondary">
            {item.mese + " " + item.anno}
          </Typography>
        </Grid>
        <Grid item xs={8} sm={9}>
          <Typography>{item.titolo}</Typography>
        </Grid>
        {!!item.attestato && (
          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                border="none"
                onClick={() =>
                  onPreviewClick({
                    image: item.attestato,
                    alt: item.titolo,
                    height: item.height,
                  })
                }
              >
                <Visibility />
              </IconButton>
            </Box>
          </Grid>
        )}
      </Grid>
    </CustomListItem>
    {!isLast && <Divider />}
  </>
);

const FormazioneCard = () => {
  const [imagePreview, setImagePreview] = useState(Object.freeze({}));
  useEffect(() => {}, [imagePreview]);

  return (
    <>
      <Dialog
        open={!!imagePreview.image}
        onClose={() => setImagePreview(Object.freeze({}))}
      >
        <img src={imagePreview.image} alt={imagePreview.alt} />
      </Dialog>
      <Card elevation={6} sx={{ mt: 1 }}>
        <List>
          {formazione
            // return an array with all distinct "anno" filed
            .map((item) => item.anno)
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((anno) => (
              <React.Fragment key={anno}>
                <AnnoListItem>
                  <ListItemText
                    key={anno}
                    primary={anno}
                    primaryTypographyProps={{
                      color: "secondary",
                      fontWeight: "bold",
                      fontSize: "h6.fontSize",
                      align: "center",
                    }}
                  />
                </AnnoListItem>
                {formazione
                  .filter((item) => item.anno === anno)
                  .map((item, i, filteredFormazione) => (
                    <RowItem
                      key={getItemKey(item)}
                      item={item}
                      onPreviewClick={setImagePreview}
                      isLast={filteredFormazione.length - 1 === i}
                    />
                  ))}
              </React.Fragment>
            ))}
        </List>
      </Card>
    </>
  );
};

const curriculumCinofilo = () => (
  <>
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <img src={LogoZampa} alt="" width="10%" height="10%" />
      <Typography variant="h4" align="center">
        Curriculum Cinofilo
      </Typography>
      <img src={LogoZampa} alt="" width="10%" height="10%" />
    </Stack>
    <Typography variant="h6" sx={{ mt: 2 }}>
      Informazioni & contatti
    </Typography>
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={6}>
        <InfoCard />
      </Grid>
      <Grid item sx={{ display: { xs: "none", md: "block" } }} md={1} />
      <Grid item xs={12} md={5}>
        <ImageCard image={ImgIoTaiSelfie} width="50%" />
      </Grid>
    </Grid>
    <Typography variant="h6" sx={{ mt: 2 }}>
      Formazione
    </Typography>
    <FormazioneCard />
  </>
);

export default curriculumCinofilo;
