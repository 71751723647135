import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
// CUSTOM COMPONENT
import { qualifiche } from "../data/qualifiche";

const QualificheCinofilia = () => {
  const [imagePreview, setImagePreview] = useState(Object.freeze({}));
  useEffect(() => {}, [imagePreview]);

  return (
    <>
      <Typography variant="h4" align="center" paddingBottom={4}>
        Qualifiche
      </Typography>
      <Dialog
        open={!!imagePreview.image}
        onClose={() => setImagePreview(Object.freeze({}))}
      >
        <img src={imagePreview.image} alt={imagePreview.alt} />
      </Dialog>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        {qualifiche.map((item) => (
          <Box
            key={item.titolo}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography variant="h6">{item.titolo}</Typography>
            {item.image && (
              <IconButton
                border="none"
                onClick={() =>
                  setImagePreview({
                    image: item.image,
                    alt: item.titolo,
                  })
                }
              >
                <Visibility />
              </IconButton>
            )}
          </Box>
        ))}
      </Stack>
    </>
  );
};

export default QualificheCinofilia;
