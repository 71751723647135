import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
// CUSTOM COMPONENT
import { info } from '../../data/cv';

const InfoCard = () => (
	<Card elevation={8}>
		<CardContent>
			<List dense>
				<ListItem>
					{info.generic}
				</ListItem>
				<ListItem>
					<Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
						Cell: &nbsp;
					</Typography>
					<Typography variant="body2" sx={{ display: 'inline' }}>
						{info.cell}
					</Typography>
				</ListItem>
				<ListItem>
					<Typography sx={{ fontWeight: 'bold', display: 'inline' }}>
						Email: &nbsp;
					</Typography>
					<Typography variant="body2" sx={{ display: 'inline' }}>
						{info.email}
					</Typography>
				</ListItem>
			</List>
		</CardContent>
	</Card>
);

export default InfoCard;