const AppointmentPage = () => (
  <iframe
    title="Prenota incontro"
    src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ0WRjynjbfLObAvwzxW9roH4-OGrvssVG0e1L30MRUkQeNGTLAdampLC_4pQiOWaLaIWMY0RJrT?gv=true"
    style={{ border: 0 }}
    width="100%"
    height="600"
    frameborder="0"
  ></iframe>
);

export default AppointmentPage;
