import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
// CUSTOM COMPONENT
import { info, formazione, esperienze, competenze, altro } from "../data/cv";

const cvVersion = "1";

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Times-Roman",
    padding: 15,
  },
  infoBox: {
    paddingTop: 10,
    margin: 5,
    fontSize: 10,
    color: "grey",
  },
  title: {
    fontFamily: "Times-Bold",
    fontSize: 24,
    textAlign: "center",
  },
  subtitle: {
    fontFamily: "Times-Italic",
    fontSize: 16,
    textAlign: "center",
  },
  header: {
    fontFamily: "Times-Bold",
    fontSize: 20,
    paddingTop: 10,
    marginVertical: 5,
  },
  body: {
    fontSize: 12,
  },
  bold: {
    fontFamily: "Times-Bold",
  },
  paragraph: {
    marginVertical: 5,
  },
});

const printCompetenzaListItem = (item, comma) => (comma ? item + ", " : item);

// Create Document Component
const CvDocument = (
  <Document
    title="CV_Luca_Neotti.pdf"
    author="Luca Neotti"
    pdfVersion={cvVersion}
    pageMode="fullScreen"
  >
    <Page size="A4" style={styles.page}>
      <View>
        <Text style={styles.subtitle}>Curriculum Vitae</Text>
        <Text style={styles.title}>Luca Neotti</Text>
      </View>
      <View style={styles.infoBox}>
        <Text>{info.generic}</Text>
        <Text>Cell: {info.cell}</Text>
        <Text>Email: {info.email}</Text>
      </View>
      <View style={styles.body}>
        <Text style={styles.header}>Formazione</Text>
        {formazione.map((item) => (
          <View key={item} style={{ flexDirection: "row", marginVertical: 5 }}>
            <Text>-</Text>
            <Text style={{ marginLeft: 5, marginRight: 10 }}>{item}</Text>
          </View>
        ))}

        <Text style={styles.header}>Esperienze lavorative</Text>
        {esperienze.map((item) => (
          <View key={item} style={styles.paragraph}>
            <Text>
              Da {item.start} a {item.end}
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.bold}>Presso: </Text>
              <Text>{item.company}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.bold}>Mansione: </Text>
              <Text>{item.task}</Text>
            </View>
            <Text style={styles.bold}>Descrizione:</Text>
            <Text>{item.description}</Text>
          </View>
        ))}
      </View>
      <View style={styles.body}>
        <Text style={styles.header}>Competenze</Text>
        {competenze.map((competenza) => {
          const label = Object.keys(competenza)[0];
          return (
            <View key={label} style={styles.paragraph}>
              <Text style={styles.bold}>{label}:</Text>
              <View style={{ flexDirection: "row" }}>
                {competenza[label].map((key, i) => (
                  <Text key={key}>
                    {printCompetenzaListItem(
                      key,
                      i < competenza[label].length - 1
                    )}
                  </Text>
                ))}
              </View>
            </View>
          );
        })}

        <Text style={styles.header}>Altro</Text>
        <View>
          {altro.map((item) => (
            <Text key={item}>{item}</Text>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default CvDocument;
