export const isDevMode =
  !!process.env.REACT_APP_DEV_MODE &&
  process.env.REACT_APP_DEV_MODE.trim() === "true";

export const sendMailLambdaName =
  process.env.REACT_APP_SEND_MAIL_LAMBDA_NAME?.trim();

export const reactAppLambdaAccessKeyId =
  process.env.REACT_APP_LAMBDA_ACCESS_KEY_ID;

export const reactAppLambdaSecretAccessKey =
  process.env.REACT_APP_LAMBDA_SECRET_ACCESS_KEY;
