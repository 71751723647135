import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../pages/Home";
import CurriculumVitae from "../pages/CurriculumVitae";
import Contatti from "../pages/Contatti";
import CinofiliaHome from "../pages/CinofiliaHome";
import CurriculumCinofilo from "../pages/CurriculumCinofilo";
import QualificheCinofilia from "../pages/QualificheCinofilia";
import Appuntamenti from "../pages/Appuntamenti";
import PDFViewer from "../pages/PDFViewer";

import { isDevMode } from "../components/env";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "curriculum-vitae",
        element: <CurriculumVitae />,
      },
      {
        path: "cinofilia",
        children: [
          {
            path: "home",
            element: <CinofiliaHome />,
          },
          {
            path: "curriculum",
            element: <CurriculumCinofilo />,
          },
          {
            path: "qualifiche",
            element: <QualificheCinofilia />,
          },
          {
            path: "appuntamenti",
            element: <Appuntamenti />,
          },
        ],
      },
      {
        path: "contatti",
        element: <Contatti />,
      },
      {
        path: "/pdf-viewer",
        element: isDevMode ? <PDFViewer /> : null,
      },
    ],
  },
]);

export default Router;
