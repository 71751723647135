import Lettura from "../static/images/attestati/lettura_vitalini_1118.jpg";
import DiscdogStrange from "../static/images/attestati/discdog_strange_0518.jpg";
import DiscdogMereu from "../static/images/attestati/discdog_mereu_1019.jpg";
import DimmiChiSei from "../static/images/attestati/dimmi_chi_sei_1119_.jpg";
import GruppoCani from "../static/images/attestati/creare_gruppo_cani_1019.jpg";
import Riconoscersi from "../static/images/attestati/riconoscersi_1120.jpg";
import K9 from "../static/images/attestati/K9_1221.jpg";
import Clicker from "../static/images/attestati/clicker_0622.jpg";
import DiversamentePit from "../static/images/attestati/Diversamente_pit_0922.jpg";
import DaPredatorioARelazione from "../static/images/attestati/da_comportamento_predatorio_alla_relazione 0223.jpg";
import GiocoMotivazione from "../static/images/attestati/giocomotivazione_0523.jpg";
import Morso from "../static/images/attestati/il_valore_del_mosrso_0523.png";
import Emotività from "../static/images/attestati/emotivita_nel_cane_0623.png";
import GestioneFrustrazione from "../static/images/attestati/gestione_frustrazione_0923.png";
import CaniCheUccidonoCani from "../static/images/attestati/cani_che_uccidono_altri_cani_0324.jpg";
import K9BSS from "../static/images/qualifiche/K9BSS.png";
import K9CTS from "../static/images/qualifiche/K9CTS.png";

export const formazione = [
  {
    anno: 2024,
    mese: "Novembre",
    titolo: "La teoria polivagale con Alexa Capra",
  },
  {
    anno: 2024,
    mese: "Ottobre",
    titolo: "Conseguimento esame K9 Cross Training Specialist",
    attestato: K9CTS,
  },
  {
    anno: 2024,
    mese: "Settembre",
    titolo: "Incontri guidati con Alexa Capra",
  },
  {
    anno: 2024,
    mese: "Marzo",
    titolo: "Conseguimento esame K9 Cross Training Balance safe&sound",
    attestato: K9BSS,
  },
  {
    anno: 2024,
    mese: "Marzo",
    titolo: 'Webinar "cani che uccidono gli altri cani" con Barbara Gallicchio',
    attestato: CaniCheUccidonoCani,
  },
  {
    anno: 2024,
    mese: "Febbraio",
    titolo: "Interazioni guidate con Alexa Capra",
  },
  {
    anno: 2024,
    mese: "Gennaio",
    titolo: "Riconoscimento qualifica da educatore di terzo livello in FISC",
  },
  {
    anno: 2023,
    mese: "Ottobre",
    titolo: 'Webinar "Do you speak K9?" con David Morettini',
  },
  {
    anno: 2023,
    mese: "Settembre",
    titolo: 'Webinar "La gestione della frustrazione" con Michele Minunno',
    attestato: GestioneFrustrazione,
  },
  {
    anno: 2023,
    mese: "Settembre",
    titolo: "Stage Gioco e motivazione con Marco Gabossi",
  },
  {
    anno: 2023,
    mese: "Settembre",
    titolo: "Incontri guidati con Alexa Capra",
  },
  {
    anno: 2023,
    mese: "Giugno",
    titolo:
      'Webinar "L\'emotività nel cane. Meccanismi di compensazione e dipendenza" con Michele Minunno',
    attestato: Emotività,
  },
  {
    anno: 2023,
    mese: "Giugno",
    titolo:
      "Giornata teorico pratica dedicata al riscaldamento e defaticamento del cane sportivo con Mirella Masini",
  },
  {
    anno: 2023,
    mese: "Maggio",
    titolo:
      'Webinar "Il valore del morso per prevenire insicurezza e impulsività: la resilienza" con Pierluigi Raffo',
    attestato: Morso,
  },
  {
    anno: 2023,
    mese: "Maggio",
    titolo: "Inizio serie incontri k9 cross training presso CharlieDog asd",
  },
  { anno: 2023, mese: "Maggio", titolo: "Incontri guidati con Alexa Capra" },
  {
    anno: 2023,
    mese: "Maggio",
    titolo: 'Stage "Gioco e motivazione" con Marco Gabossi',
    attestato: GiocoMotivazione,
  },
  {
    anno: 2023,
    mese: "Marzo",
    titolo: "Inizio collaborazione con Destini Incrociati ASD",
  },
  {
    anno: 2023,
    mese: "Febbraio",
    titolo: 'Stage "Dalla predazione alla relazione" con Michele Minunno',
    attestato: DaPredatorioARelazione,
  },
  { anno: 2023, mese: "Gennaio", titolo: "Incontri guidati con Alexa Capra" },
  {
    anno: 2022,
    mese: "Ottobre",
    titolo: 'Webinar "Spazio e libertà" con Simone dalla Valle',
  },
  {
    anno: 2022,
    mese: "Settembre",
    titolo:
      'Stage "Diversamente Pit" con Matteo Castiglioni e Rebecca della Pietà',
    attestato: DiversamentePit,
  },
  {
    anno: 2022,
    mese: "Settembre",
    titolo: "Incontri guidati con Alexa Capra (in affiancamento)",
  },
  {
    anno: 2022,
    mese: "Luglio",
    titolo: 'Stage "Nose-life DoloMitico" con Simone dalla Valle',
  },
  {
    anno: 2022,
    mese: "Giugno",
    titolo: "Stage clicker training con Ilaria Boero",
    attestato: Clicker,
  },
  { anno: 2022, mese: "Aprile", titolo: "Incontri guidati con Alexa Capra" },
  {
    anno: 2022,
    mese: "Febbraio",
    titolo:
      'Stage "Diversamente Pit" con Matteo Castiglioni e Rebecca della Pietà',
  },
  {
    anno: 2021,
    mese: "Dicembre",
    titolo: "Stage k9 cross training con Filippo Orsenico",
    attestato: K9,
  },
  {
    anno: 2021,
    mese: "Novembre",
    titolo: "Incontri guidati con Alexa Capra",
  },
  {
    anno: 2021,
    mese: "Aprile",
    titolo:
      'Stage "Diversamente Pit" con Matteo Castiglioni e Rebecca della Pietà',
  },
  {
    anno: 2021,
    mese: "Gennaio",
    titolo: 'Webinar "Cani che odiano cani" con Alexa Capra',
  },
  {
    anno: 2020,
    mese: "Dicembre",
    titolo: 'Webinar "Il clicker" con Alexa Capra',
  },
  {
    anno: 2020,
    mese: "Novembre",
    titolo: 'Stage "Ri-conosciamoci" con Simone dalla Valle',
    attestato: Riconoscersi,
  },
  { anno: 2020, mese: "Ottobre", titolo: "Valutazioni con Alexa Capra" },
  {
    anno: 2019,
    mese: "Novembre",
    titolo:
      '"Dimmi chi sei" David Morettini, master istruzione cinofila università di Parma',
    attestato: DimmiChiSei,
  },
  {
    anno: 2019,
    mese: "Ottobre",
    titolo:
      '"Come creare un gruppo di cani che possano interagire in modo costruttivo tra di loro" Luca Scannavacca, master istruzione cinofila università di Parma',
    attestato: GruppoCani,
  },

  {
    anno: 2019,
    mese: "Ottobre",
    titolo: "Stage di discdog con Fabio Mereu",
    attestato: DiscdogMereu,
  },
  {
    anno: 2019,
    mese: "Settembre",
    titolo: 'Stage "Giochiamo" con Simone dalla Valle',
  },
  {
    anno: 2019,
    mese: "Marzo",
    titolo: 'Stage "apprendimento sociale" con Giulia Pedon',
  },
  {
    anno: 2019,
    mese: "Febbraio",
    titolo: '"Strange Stage 4 Disc Dog" con diversi reloatori',
  },
  {
    anno: 2019,
    mese: "Gennaio",
    titolo: 'Stage "valutazioni in canile" con Ivano Vitalini',
  },
  {
    anno: 2018,
    mese: "Dicembre",
    titolo:
      'Stage "La possessività nelle dinamiche sociali" con Flavia Pisciotta',
  },
  {
    anno: 2018,
    mese: "Novembre",
    titolo: 'Stage "La lettura del cane" con Ivano Vitalini',
    attestato: Lettura,
  },
  {
    anno: 2018,
    mese: "Luglio",
    titolo: 'Stage "ginnastica propriocettiva" con Sabrina Rossi',
  },
  {
    anno: 2018,
    mese: "Maggio",
    titolo: '"Strange Stage 4 Disc Dog" con diversi reloatori',
    attestato: DiscdogStrange,
  },
  {
    anno: 2018,
    mese: "Aprile",
    titolo:
      "Giornata introduttiva al metodo TTouch sui cani con Valeria Rapezzi",
  },
  {
    anno: 2018,
    mese: "Marzo",
    titolo: 'Stage "ginnastica propriocettiva" con Sabrina Rossi',
  },
  {
    anno: 2018,
    mese: "Marzo",
    titolo: "Conseguimento esame di Educatore Cinofilo FICSS",
  },
  { anno: 2018, mese: "Marzo", titolo: "Stage di disc dog con Fabio Sacco" },
  {
    anno: 2018,
    mese: "Gennaio",
    titolo:
      'Stage "Come aiutare il cane a superare l\'ansia" con Michele Minunno',
  },
  {
    anno: 2017,
    mese: "Dicembre",
    titolo: 'Stage "Reattività, aggressività o predazione" con Giusy D\'Angelo',
  },
  {
    anno: 2017,
    mese: "Novembre",
    titolo: "Giornata di classi di comunicazione con Giusy D'Angelo.",
  },
  {
    anno: 2017,
    mese: "Ottobre",
    titolo: "Inizio percorso di disc dog con Andrea Melpignano",
  },
  {
    anno: 2017,
    mese: "Settembre",
    titolo: 'Stage "Clicker training" con Ilaria Boero',
  },
  {
    anno: 2017,
    mese: "Luglio",
    titolo: "Giornata di classi di comunicazione con Giusy D'Angelo",
  },
  { anno: 2017, mese: "Maggio", titolo: "Stage di disc dog con Adrian Stoica" },
  {
    anno: 2017,
    mese: "Marzo",
    titolo: 'Stage "Prossemica e comunicazione" con Michele Minunno',
  },
  {
    anno: 2017,
    mese: "Febbraio",
    titolo: 'Stage "Nosework II livello" con Simone dalla Valle',
  },
  {
    anno: 2016,
    mese: "Novembre",
    titolo: "Seminario sulle classi di comunicazione con Giusi D'Angelo",
  },
  {
    anno: 2016,
    mese: "Novembre",
    titolo: 'Stage "La valutazione del cane" con Giusy D\'Angelo',
  },
  {
    anno: 2016,
    mese: "Ottobre",
    titolo: "Uditore a visite comportamentali con Giuglia Pedon",
  },
  {
    anno: 2016,
    mese: "Ottobre",
    titolo:
      "Stage con Giusy D'Angelo: Dalla valutazione alle Classi di comunicazione",
  },
  {
    anno: 2016,
    mese: "Settembre",
    titolo:
      "Conferenza “Il randagismo canino nel sud Italia” di Michele Minunno",
  },
  {
    anno: 2016,
    mese: "Settembre",
    titolo: 'Stage "Le marcature" con Giusi D\'Angelo',
  },
  {
    anno: 2016,
    mese: "Giugno",
    titolo: "Inizio tirocinio con AMazingDog asd",
  },
  {
    anno: 2016,
    mese: "Maggio",
    titolo: "Conferenza “Cittadini a 6 zampe” di AMazingDog asd",
  },
  { anno: 2016, mese: "Marzo", titolo: "Inizio percorso con Goran" },
];
