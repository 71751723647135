import React from "react";
import { useTheme, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
// CUSTOM COMPONENT
import InfoCard from "../components/InfoCard";
import ImageCard from "../components/ImageCard";
import CvDowloadButton from "../PDF/CvDowloadButton";
import { formazione, esperienze, competenze, altro } from "../data/cv";
// IMAGES
import ImgBiciMontagna from "../static/images/img-bici-montagna.jpg";

const FormazioneCard = () => (
  <Card elevation={8} sx={{ mt: 1 }}>
    <List>
      {formazione.map((item, i) => (
        <React.Fragment key={item}>
          <ListItem>{item}</ListItem>
          {formazione.length - 1 !== i ? <Divider /> : null}
        </React.Fragment>
      ))}
    </List>
  </Card>
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.grey.light,
  },
}));

const EsperienzeTable = () => (
  <TableContainer component={Paper} elevation={8} sx={{ mt: 1 }}>
    <Table>
      <TableHead>
        <StyledTableRow>
          <StyledTableCell>Data inizio</StyledTableCell>
          <StyledTableCell>Data fine</StyledTableCell>
          <StyledTableCell>Azienda</StyledTableCell>
          <StyledTableCell>Mansione</StyledTableCell>
          <StyledTableCell>Descrizione</StyledTableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
        {esperienze.map((item) => (
          <StyledTableRow key={item.start}>
            <StyledTableCell>{item.start}</StyledTableCell>
            <StyledTableCell>{item.end}</StyledTableCell>
            <StyledTableCell>{item.company}</StyledTableCell>
            <StyledTableCell>{item.task}</StyledTableCell>
            <StyledTableCell>{item.description}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const CompetenzaItem = ({ competenza, label }) => (
  <Grid item xs={7} sm={10}>
    <Stack sx={{ display: "block" }}>
      {competenza[label].map((key) => (
        <Chip
          key={key}
          label={key}
          color="secondary"
          variant="outlined"
          sx={{ m: 0.5 }}
        />
      ))}
    </Stack>
  </Grid>
);

const CompetenzeList = () => {
  const theme = useTheme();
  return competenze.map((competenza, i) => {
    const label = Object.keys(competenza)[0];
    const bgColor = i % 2 === 0 ? theme.palette.grey.light : null;
    return (
      <Box sx={{ backgroundColor: bgColor, p: 1.5 }} key={label}>
        <Grid container spacing={1} alignItems="center" key={label}>
          <Grid item xs={5} sm={2}>
            <Box>{label}:</Box>
          </Grid>
          <CompetenzaItem competenza={competenza} label={label} />
        </Grid>
      </Box>
    );
  });
};

const CompetenzeCard = () => (
  <Card elevation={8} sx={{ mt: 1 }}>
    <CompetenzeList />
  </Card>
);

const ContributiCard = () => (
  <Stack direction="row" spacing={2}>
    <Link href="https://github.com/eclipse/packages">
      <Typography style={{ whiteSpace: "normal" }}>eclipse/package</Typography>
    </Link>
    <Link href="https://github.com/eclipse-ditto/ditto">
      <Typography style={{ whiteSpace: "normal" }}>
        eclipse-ditto/ditto
      </Typography>
    </Link>
  </Stack>
);

const AltroCard = () => (
  <Grid container spacing={1}>
    {altro.map((info) => (
      <Grid item xs={12} sm={4} lg={3} key={info}>
        <Chip
          sx={{ my: 1 }}
          style={{ height: "100%" }}
          color="secondary"
          variant="contained"
          label={
            <Typography style={{ whiteSpace: "normal" }}>{info}</Typography>
          }
        ></Chip>
      </Grid>
    ))}
  </Grid>
);

const curriculumVitae = () => (
  <>
    <Grid container>
      <Grid item xs={2}>
        <CvDowloadButton />
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h4" align="center">
          Curriculum Vitae
        </Typography>
      </Grid>
      <Grid item xs={2} />
    </Grid>
    <Typography variant="h6" sx={{ mt: 2 }}>
      Informazioni & contatti
    </Typography>
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12} md={6}>
        <InfoCard />
      </Grid>
      <Grid item sx={{ display: { xs: "none", md: "block" } }} md={1} />
      <Grid item xs={12} md={5}>
        <ImageCard image={ImgBiciMontagna} width="60%" />
      </Grid>
    </Grid>
    <Typography variant="h6" sx={{ mt: 2 }}>
      Formazione
    </Typography>
    <FormazioneCard />
    <Typography variant="h6" sx={{ mt: 2 }}>
      Esperienze lavorative
    </Typography>
    <EsperienzeTable />
    <Typography variant="h6" sx={{ mt: 2 }}>
      Competenze
    </Typography>
    <CompetenzeCard />
    <Typography variant="h6" sx={{ mt: 2 }}>
      Contributi
    </Typography>
    <ContributiCard />
    <Typography variant="h6" sx={{ mt: 2 }}>
      Altro
    </Typography>
    <AltroCard />
  </>
);

export default curriculumVitae;
