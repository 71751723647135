import React from 'react';
import { usePDF } from '@react-pdf/renderer';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
// CUSOTM COMPONENT
import CvDocument from './CvDocument';

const DownloadButton = ({ disabled = false, tooltipMessage = "Download in pdf format", children }) => (
    <IconButton variant="outlined" color="secondary" disabled={disabled}>
        <Tooltip title={tooltipMessage}>
            {children}
        </Tooltip>
    </IconButton >
);

const CvDownloadButton = () => {
    const [instance] = usePDF({ document: CvDocument });

    if (instance.loading) {
        console.log("loading pdf")
        return <DownloadButton disabled tooltipMessage="Loading...">
            <DownloadIcon />
        </DownloadButton>
    }

    if (instance.error) {
        console.log("loading pdf error", instance.error)
        return <DownloadButton disabled tooltipMessage="Error!">
            <DownloadIcon />
        </DownloadButton>
    }

    return (
        <DownloadButton>
            <Link href={instance.url}
                download="cv_luca_neotti.pdf"
                color="secondary"
            >
                <DownloadIcon />
            </Link>
        </DownloadButton>
    );
};

export default CvDownloadButton;