import { Outlet } from "react-router-dom";
import { blue, green, grey } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
// CUSTOM COMPONENT
import AppBar from "./components/AppBar";
import Footer from "./components/Footer";

const theme = createTheme({
  palette: {
    primary: {
      light: green[300],
      main: green[400],
    },
    secondary: {
      main: blue[800],
    },
    white: {
      main: blue[50],
    },
    grey: {
      lighter: grey[100],
      light: grey[200],
      main: grey[600],
      dark: grey[800],
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: [
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
    <AppBar />
    <Box sx={{ p: 2 }}>
      <Outlet />
    </Box>
    <Footer />
  </ThemeProvider>
);

export default App;
