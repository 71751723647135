import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

const ImageCard = ({width, image}) => (
	<Card
		align="center"
		variant="outlined"
		sx={{border: 0}}
	>
		<CardMedia
			component="img"
			image={image}
			sx={{ objectFit: 'contain', width: width }}
		/>
	</Card>
);

export default ImageCard;