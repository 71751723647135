import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const useExpandedItemSx = (open) => {
  const theme = useTheme();
  return {
    backgroundColor: open && theme.palette.grey.light,
  };
};

const getMenuItemtext = (link, label, primaryTypographyProps) =>
  link ? (
    <ListItem component={Link} href={link} sx={{ padding: 0 }}>
      <ListItemText
        primary={label}
        primaryTypographyProps={{
          color: "secondary",
          ...primaryTypographyProps,
        }}
      />
    </ListItem>
  ) : (
    <ListItemText
      primary={label}
      primaryTypographyProps={{
        color: "secondary",
        ...primaryTypographyProps,
      }}
    />
  );

const Expandible = ({
  Icon,
  onClickArrow,
  open,
  link,
  label,
  primaryTypographyProps,
}) => {
  const theme = useTheme();
  const circleBorder = {
    borderStyle: "solid",
    borderRadius: "20%",
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.light,
  };
  return (
    <ListItemButton sx={useExpandedItemSx(open)}>
      <ListItemIcon>
        {Icon && <Icon sx={{ m: 0.5 }} color="secondary" />}
      </ListItemIcon>
      {getMenuItemtext(link, label, primaryTypographyProps)}
      {open ? (
        <ExpandLess onClick={onClickArrow} style={circleBorder} />
      ) : (
        <ExpandMore onClick={onClickArrow} style={circleBorder} />
      )}
    </ListItemButton>
  );
};

const childrenWithProps = (children, props) =>
  React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      console.log(props);
      return React.cloneElement(child, props);
    }
    return child;
  });

const ExpandibleListItem = ({
  Icon,
  label,
  link,
  onClickArrow,
  open,
  children,
  primaryTypographyProps,
}) => {
  return (
    <>
      <Expandible
        Icon={Icon}
        label={label}
        link={link}
        onClickArrow={onClickArrow}
        open={open}
        primaryTypographyProps={primaryTypographyProps}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {childrenWithProps(children, { sx: useExpandedItemSx(open) })}
        </List>
      </Collapse>
    </>
  );
};

export default ExpandibleListItem;
