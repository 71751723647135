import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
// ICONS
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import DescriptionIcon from "@mui/icons-material/Description";
import PetsIcon from "@mui/icons-material/Pets";
import ContactPageIcon from "@mui/icons-material/ContactPage";
// CUSTOM COMPONENTS
import ExpandibleListItem from "../ExpandibleListItem";

const SiteMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [cinofiliaOpen, setCinofiliaOpen] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickCinofiliaArrow = () => {
    setCinofiliaOpen(!cinofiliaOpen);
  };

  const SiteMenuItem = ({ Icon, link, label, sx }) => (
    <Link href={link} underline="none">
      <ListItemButton onClick={handleClose} sx={sx}>
        <ListItemIcon>
          {!!Icon ? <Icon sx={{ m: 0.5 }} color="secondary" /> : null}
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{ color: "secondary" }}
        />
      </ListItemButton>
    </Link>
  );

  return (
    <>
      <IconButton onClick={handleClick}>
        <MenuIcon color="secondary" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <List>
          <SiteMenuItem Icon={HomeIcon} link="/" label="Home" />
          <SiteMenuItem
            Icon={DescriptionIcon}
            link="/curriculum-vitae"
            label="Curriculum Vitae"
          />
          <ExpandibleListItem
            Icon={PetsIcon}
            label="Cinofilia"
            onClickArrow={onClickCinofiliaArrow}
            link="/cinofilia/home"
            open={cinofiliaOpen}
          >
            <SiteMenuItem
              link="/cinofilia/curriculum"
              label="Curriculum"
              bgcolor="green"
            />
            <SiteMenuItem link="/cinofilia/qualifiche" label="Qualifiche" />
            <SiteMenuItem
              link="/cinofilia/appuntamenti"
              label="Prenota appuntamento"
            />
          </ExpandibleListItem>
          <SiteMenuItem
            Icon={ContactPageIcon}
            link="/contatti"
            label="Contatti"
          />
        </List>
      </Menu>
    </>
  );
};

export default SiteMenu;
