import React from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
// ICONS
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Links = () => {
  const navigation = useLocation();
  const isCinofilia = navigation.pathname.includes("cinofilia");

  return (
    <Box>
      {!isCinofilia && (
        <IconButton>
          <Link
            href="https://www.linkedin.com/in/luca-neotti"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon />
          </Link>
        </IconButton>
      )}
      <IconButton>
        <Link
          href="https://www.instagram.com/luca_staffy_tai/"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon />
        </Link>
      </IconButton>
    </Box>
  );
};

const Footer = () => (
  <Box
    sx={{
      bottom: 0,
      m: 1,
    }}
    component="footer"
  >
    <Divider sx={{ m: 1 }} />
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography sx={{ display: "inline" }}>Designed with&nbsp;</Typography>
        <Link
          href="https://reactjs.org/"
          target="_blank"
          rel="noreferrer"
          underline="none"
          sx={{ display: "inline" }}
        >
          React
        </Link>
        <Typography sx={{ display: "inline" }}>, powered with&nbsp;</Typography>
        <Tooltip title="Deployed by Amplify and routed by Route53">
          <Link
            href="https://aws.amazon.com/it/"
            target="_blank"
            rel="noreferrer"
            underline="none"
            sx={{ display: "inline" }}
          >
            AWS
          </Link>
        </Tooltip>
        <Typography>By Luca Neotti</Typography>
      </Box>
      <Links sx={{ display: "flex" }} justifyContent="flex-end" />
      <Typography sx={{ display: { xs: "none", sm: "flex" } }}>
        Copyright ©2022
      </Typography>
    </Box>
    <Typography sx={{ display: { sm: "none" } }}>Copyright ©2022</Typography>
  </Box>
);

export default Footer;
