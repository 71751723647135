import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// IMAGES
import snake1990 from "../static/images/snake1990.png";
import siteLogo from "../static/images/site-logo.png";
import mancnc from "../static/images/man_cnc.png";
import mago from "../static/images/mago.png";
import hobbypasta from "../static/images/hobbypasta.png";
import staffy from "../static/images/staffy.png";

const anniEsperienza = new Date().getFullYear() - 2014;

const StretchedItem = ({ children, md = 4, image }) => {
  return (
    <Grid item xs={12} md={md}>
      <Card textalign="justify" elevation={3}>
        <CardContent>{children}</CardContent>
        {image && <CardMedia component="img" height={200} image={image} />}
      </Card>
    </Grid>
  );
};

const Logo = () => (
  <>
    <Box align="center" display={{ xs: "none", sm: "block" }}>
      <img src={siteLogo} alt="LUCA NEOTTI" margin={2} />
    </Box>
    <Box align="center" display={{ xs: "block", sm: "none" }}>
      <img
        src={siteLogo}
        alt="LUCA NEOTTI"
        width="40%"
        height="40%"
        margin={1}
      />
    </Box>
  </>
);

const Introduzione = () => (
  <Box alignItems="center" padding={3}>
    <Typography variant="h5">Mi presento</Typography>
    <Typography align="justify">
      Mi chiamo Luca, sono nato il 5 maggio del 1989 e abito a Noceto (PR).
    </Typography>
    <Typography align="justify">
      Sono un software architect e developer con più di {anniEsperienza} anni di
      esperienza nella progettazione, sviluppo e implementazione di applicazioni
      software. Forte background nella programmazione Java, React e nodejs e
      nell'utilizzo dei principali framework e librerie. Ho sviluppato una
      conoscenza approfondita delle architetture informatiche, dei design
      pattern e delle best practice per lo sviluppo di software moderno e mi
      ritengo grado di analizzare i requisiti del progetto, definire specifiche
      tecniche e implementare soluzioni software scalabili, robuste e sicure.
    </Typography>
  </Box>
);

const Presentazione = () => (
  <Box display={{ xs: "block", sm: "flex" }} alignItems="center" padding={3}>
    <Box>
      <Typography align="justify">
        Ho la passione per l'informatica da quando sono piccolo: ricordo ancora
        il vecchio pc di mio padre con il quale giocavo ad una versione molto
        primitiva di Snake. Inserivo il floppy disk da 5¼ pollici, un paio di
        click col mouse e appariva il serpente giallo su sfondo blu.
      </Typography>
      <Typography align="justify">
        Ho sempre avuto la curiosità di capire come potesse essere creato e
        programmato quel serpentello. Dalle scuole medie ho cominciato a
        scrivere i primissimi programmi sperimentali che oggi mi sembrano una
        banalità, ma allora con gli strumenti che avevo a disposizione erano
        grandi successi. Ne andavo fiero e ne ero entusiasta.
      </Typography>
      <Typography align="justify">
        Scegliere la scuola superiore non è stato difficile: ITIS con indirizzo
        informatico. In quel periodo è nata un'altra passione che non ho più
        abbandonato: la cucina. Più tardi ho provato anche a far diventare
        questa passione un lavoro, capendo però che l'informatica era più adatta
        a me. Ho comunque tenuto la cucina come hobby e mi diverto a cucinare
        per me e per i miei cari quando posso. Soprattutto mi piace assaggiare
        piatti nei ristoranti per prendere ispirazione e sperimentare tecniche e
        ricette.
      </Typography>
    </Box>
    <Box paddingLeft={3} display={{ xs: "none", sm: "flex" }}>
      <img src={snake1990} alt="snake1990.jpg" />
    </Box>
    <Box padding={3} display={{ xs: "flex", sm: "none" }}>
      <img src={snake1990} alt="snake1990.jpg" className="snake-image" />
    </Box>
  </Box>
);

const Lavoro = () => (
  <Box display={{ xs: "block", sm: "flex" }} alignItems="center">
    <Box padding={3} display={{ xs: "none", sm: "flex" }}>
      <img src={mancnc} alt="mancnc.jpg" width="300" height="250" />
    </Box>
    <Box padding={3}>
      <Typography align="justify">
        Dopo la scuola superiore ho deciso di cominciare a lavorare per
        costruirmi la mia indipendenza. Inizialmente ho fatto l'elettricista,
        nulla a che fare con l'informatica, ma mentre cercavo il lavoro che
        avrei voluto non volevo starmene con le mani in mano. Ho anche lavorato
        per l'azienda di mio padre che operava in ambito metalmeccanico nel
        mondo automotive. Qui ho sperimentato la programmazione di macchine CNC
        (controllo numerico computerizzato) e gli studi mi sono tornati molto
        utili. Non conoscevo il linguaggio utilizzato, ma la logica imparata a
        scuola mi è servita da base per produrre programmi efficaci.
      </Typography>
      <Typography align="justify">
        La mia esperienza da programmatore inizia con un corso in una software
        house per cui ho lavorato terminato il corso. Ho lavorato per società
        che operavano nel mondo bancario e delle assicurazioni, progettando e
        sviluppando software gestionali per il controllo delle transizione e
        delle coperture assicurative. Successivamente ho lavorato per un'azienda
        parmense che operava in campo food & beverage fornendo un applicativo
        ERP proprietario. Mi sono trasferito a Parma ed ho preso parte al
        rinnovo di questo prodotto che utilizzava tecnologie ormai obsolete. Ora
        lavoro per una società di consulenza che supporta le imprese nella
        trasformazione digitale, e in particolare seguo un importante
        multinazionale nell'ambito dell'energia nel processo di platformization
        e nell'inserimento di un componente digital-twin.
      </Typography>
    </Box>
    <Box padding={3} display={{ xs: "block", sm: "none" }}>
      <img src={mancnc} alt="mancnc.jpg" width="300" height="250" />
    </Box>
  </Box>
);

const Hobby = () => (
  <>
    <Typography variant="h5" margin={1} marginTop={3}>
      Hobby
    </Typography>
    <Grid container direction={{ md: "row", xs: "column" }} spacing={2}>
      <StretchedItem image={hobbypasta}>
        <Typography align="justify" margin={1}>
          Mi è sempre piaciuto seguire la nonna cucinare, da piccolo la aiutavo
          a fare la pasta fresca. Facevamo i ravioli, i tortelli cremaschi, le
          lasagne, le tagliatelle e con la pasta che avanzava mi divertivo a
          fare formati strani che solitamente diventavano la mia cena
          accompagnati da qualche condimento. Alle medie e superiori di tanto in
          tanto andavo al ristorante di mia zia a sbirciare e carpire segreti
          del mestire che poi cercavo di riproporre a casa. Quasi per caso mi
          capitò l'opportunità di poter provare a far diventare questa passione
          un lavoro. Lessi un annuncio di un ristorante che cercava un aiuto in
          cucina anche senza esperienza. Feci il colloquio e andò bene. Sono
          stati 7 mesi intensi e pesanti, nel weekend facevo anche 13/14 ore al
          giorno, ma mi piaceva. Il locale subì una crisi e così iniziò la mia
          carriera da programmatore.
        </Typography>
      </StretchedItem>
      <StretchedItem image={staffy}>
        <Typography align="justify" margin={1}>
          Dal 2016 ho sviluppato una forte attrazione per il mondo cinofilo
          soprattutto quel che riguarda l'etologia e la comunicazione. Ho
          seguito un corso e sono diventato educatore cinofilo nel 2017. Da li
          ho intrapreso un percorso anche professionalizzante seguendo
          moltissimi seminari ed incontri cinofili riguardo svariati temi tra
          cui anche lo sport. Tai è con me da poco prima che diventassi
          educatore, insieme ci divertiamo a fare passeggiate (ci piace molto la
          montagna) e facciamo sport e attività varie in maniera ludica. Ho
          instaurato un bellissimo rapporto con lui, ho imparato ad ascoltarlo e
          capirlo meglio durante tutte le nostre esperienze, soprattutto durante
          gli stage che abbiamo seguito. Non mi stanco mai di partecipare a
          questi incontri perchè ogni volta mi sembra di aggiungere un pezzetto
          al mio bagaglio, qualcosa di nuovo o qualcosa che riesco a comprendere
          meglio.
        </Typography>
      </StretchedItem>
      <StretchedItem image={mago}>
        <Typography align="justify" margin={1}>
          Un'altro hobby è la magia, a cui mi sono avvicinato nel 2019 quando ho
          scoperto di avere un negozio del settore a pochi chilometri. Questa
          passione in realtà è cominciata molto prima guardando video su
          yuotube, mi ha sempre incuriosito capire il segreto dietro ad un
          effetto. Ho seguito un paio di corsi e mi diletto a performare qualche
          gioco per i familiari, ma più che esibirmi mi interessa vedere nuove
          performance e cercare di capire il trucco. Mi piacciono anche i mazzi
          di carte particolari, alcuni sono vere e proprie opere d'arte. Ne ho
          una piccola collezione personale, alcune comprate anche per sostenere
          la creatività e i progetti di persone che vedo e seguo sui social.
        </Typography>
      </StretchedItem>
      <StretchedItem md={12}>
        <Typography align="justify" margin={1}>
          Ultimamente la domotica mi ha rapito. Ho sviluppato un progetto per il
          mio appartamento con cui posso gestire riscaldamento e luci;
          controllare i consumi dei singoli elettrodomestici; controllare
          umidità delle piante; comandare la tv con la voce. Quello a cui aspiro
          è avere un sistema il più autonomo possibile, che mi possa portare
          anche ad un risparmio energetico.
        </Typography>
      </StretchedItem>
    </Grid>
  </>
);

const Politica = () => (
  <>
    <Typography variant="h5" margin={1} marginTop={3}>
      Impegno politico
    </Typography>
    <Typography align="justify" margin={1}>
      Nel 2017 sono entrato a far parte della consulta giovani del comune in cui
      ho abitato fino a quando mi sono trasferito altrove per lavoro. Ho
      cominciato ad occuparmi della parte di gestione dei social media del
      comune e di organizzare alcuni eventi per bambini e ragazzi del paese. Dal
      2019 faccio parte del consiglio comunale ed in particolare ricopro la
      figura di consigliere per la tecnologia e l'informatica. Durante il mio
      incarico mi sono occupato di rinnovare e seguire alcuni aspetti
      riguardanti l'adeguamento tecnologico ed informatico dei sistemi
      utilizzati Ad esempio ho seguito il percorso di cloud porting dei software
      utilizzati, il rinnovo del parco macchine degli uffici, l'installazione
      del centralino telefonico cloud e il rinnovo della connessione internet e
      della rete cablata. Ho messo a disposizione le mie conoscenze in ambito
      tecnologico e informatico a favore dei cittadini, mettendo il comune e i
      sui dipendenti, nelle condizioni di poter erogare servizi in maniera più
      efficace rispetto al passato. Da questa esperienza ho appreso meglio come
      gestire emergenze e progetti a lungo termine che richiedono la
      partecipazione di più attori.
    </Typography>
  </>
);

const Home = () => (
  <>
    <Logo />
    <Introduzione />
    <Presentazione />
    <Lavoro />
    <Hobby />
    <Politica />
  </>
);

export default Home;
