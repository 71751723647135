import React from "react";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// CUSTOM COMPONENT
import SiteMenu from "./SiteMenu";
import { isDevMode } from "../env";
// IMAGES
import avatarImage from "../../static/images/avatar.jpg";

const SiteAppBar = () => (
  <AppBar position="sticky">
    <Toolbar sx={{ display: { xs: "none", sm: "flex" } }}>
      <SiteMenu />
      <Box align="left" sx={{ flexGrow: 1 }}>
        {isDevMode && <div>DEV MODE</div>}
      </Box>
      <Avatar alt="LN" src={avatarImage} sx={{ width: 56, height: 56 }} />
    </Toolbar>

    {/* Only for sx screen size*/}
    <Toolbar
      sx={{ display: { xs: "flex", sm: "none" } }}
      style={{ minHeight: 28 }}
    >
      <SiteMenu />
      <Box align="right" sx={{ flexGrow: 1 }}>
        {isDevMode && <div>DEV MODE</div>}
      </Box>
    </Toolbar>
  </AppBar>
);

export default SiteAppBar;
