import AWS from "aws-sdk";
import { InvokeCommand, LambdaClient, LogType } from "@aws-sdk/client-lambda";
import {
  reactAppLambdaAccessKeyId,
  reactAppLambdaSecretAccessKey,
} from "../env";

const invoke = async (funcName, payload) => {
  const credentials = new AWS.Credentials({
    accessKeyId: reactAppLambdaAccessKeyId,
    secretAccessKey: reactAppLambdaSecretAccessKey,
  });
  const client = new LambdaClient({
    credentials,
    region: "eu-central-1",
  });
  const command = new InvokeCommand({
    FunctionName: funcName,
    Payload: JSON.stringify(payload),
    LogType: LogType.Tail,
    InvocationType: "Event",
  });

  return client.send(command);
};

export default invoke;
