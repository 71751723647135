import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

// CUSTOM COMPONENTS
import InfoCard from "../components/InfoCard";
import ContactForm from "../components/ContactForm";

const Contatti = () => (
  <>
    <Typography variant="h6" sx={{ mt: 2 }}>
      Informazioni & contatti
    </Typography>
    <InfoCard />
    <Typography variant="h6" sx={{ mt: 2 }}>
      Scrivimi
    </Typography>
    <Card elevation={8}>
      <CardContent>
        <ContactForm />
      </CardContent>
    </Card>
  </>
);

export default Contatti;
