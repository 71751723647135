import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
// CUSTOM COMPONENT
import CvDocument from '../PDF/CvDocument';
import CvDowloadButton from '../PDF/CvDowloadButton';

const PDFViewerPage = () => (
	<>
		<PDFViewer width="100%" height={500}>
			{CvDocument}
		</PDFViewer>
		<CvDowloadButton />
	</>
);

export default PDFViewerPage;